export default {
  methods: {
    setDeletedClass({ row }) {
      if (row?.deleted_at) {
        return "deleted";
      }
      return "cursor-pointer";
    },

    isDeleted(model) {
      return !!model.deleted_at;
    },
  },
};
